/**
 * Returns the offset object relative to the top of the page
 * */
export const getOffset = (elem) => {
  let x = 0,
    y = 0;

  while (elem) {
    x += elem.offsetLeft;
    y += elem.offsetTop;
    elem = elem.offsetParent;
  }

  return { left: x, top: y };
};
