import React from 'react';
import PropTypes from 'prop-types';

import s from 'styled-components';
import LoadingSpinner from '@grnhse/seedling/lib/azalea/components/loading_spinner';

import { padding } from '@grnhse/seedling/lib/azalea/constants';

export const ITEM_WIDTH = '100px';
export const ITEM_MARGIN_HORIZONTAL = padding.small;
export const ITEM_MARGIN_VERTICAL = padding.tiny;

/**
 * A simple class that renders a file preview if possible.
 *
 * If file preview isn't supported for the file type, it doesn't render anything.
 * */
export default class FilePreview extends React.Component {
  static propTypes = {
    file: PropTypes.oneOfType([
      PropTypes.instanceOf(window.File),

      // used mostly for testing.
      // recommended to pass `File`
      PropTypes.object,
    ]),

    // a url can be passed instead of a file
    // this will only be heeded if `file` isn't passed.
    fileUrl: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    if (this.props.file) {
      this.setState({ loading: true });
      this.beginLoad();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.file !== this.props.file) {
      this.beginLoad();
    }
  }

  beginLoad() {
    if (!this.isPreviewSupported()) {
      return;
    }

    // attach the file to the img so it knows what to display
    this.img.file = this.props.file;

    const reader = new FileReader();
    reader.onload = this.onImageLoad;

    // start the async read of the file
    reader.readAsDataURL(this.props.file);
  }

  onImageLoad = (evt) => {
    this.setState({
      loading: false,
    });

    const src = evt.target.result;

    if (src) {
      // this needs to be done outside the react render as it will try to re-render
      // the component and blow away the state of the img
      this.img.src = src;
    }
  };

  isPreviewSupported() {
    // if no file is provided, just assume for now that we support it
    if (!this.props.file) {
      return true;
    }

    const imageType = /^image\//;
    return imageType.test(this.props.file.type);
  }

  render() {
    if (!this.isPreviewSupported()) {
      return <span className="not-supported" />;
    }

    // lazily done this way instead of using a react components style because the raw ref
    // to the DOM component is needed. If a react-components wrapper was used, we would get
    // that as a reference instead.
    //
    // TODO: Probably should be fixed?
    let imgStyle = {
      display: 'none',
      width: ITEM_WIDTH,
      height: 'auto',
      margin: `${ITEM_MARGIN_VERTICAL} ${ITEM_MARGIN_HORIZONTAL}`,
    };

    if (!this.state.loading) {
      imgStyle.display = 'inline-block';
    }

    let localResource = true;

    if (this.props.fileUrl && !this.props.file) {
      localResource = false;
    }

    return (
      <MainContainer>
        {this.state.loading && (
          <LoadingDummyContainer>
            <LoadingSpinner size="small" />
          </LoadingDummyContainer>
        )}

        {localResource ? (
          <img
            className="preview"
            style={imgStyle}
            ref={(img) => {
              this.img = img;
            }}
            onLoad={this.onImageLoad}
          />
        ) : (
          <img src={this.props.fileUrl} className="preview" style={imgStyle} />
        )}
      </MainContainer>
    );
  }
}

const MainContainer = s.div`
  display: inline-block;
`;

const LoadingDummyContainer = s.div`
  display: inline-block;
  width:  ${ITEM_WIDTH};
  height: 120px;

  & > * {
    top: 40%;
    left: 40%;
  }
`;
