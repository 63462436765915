import ProspectQuestion from 'events/models/prospect_question';
// @ts-expect-error - TS7016 - Could not find a declaration file for module 'shared/utils/positionUtils'. 'app/webpack/javascripts/shared/utils/positionUtils.js' implicitly has an 'any' type.
import { getOffset } from 'shared/utils/positionUtils';

/**
 * To help users fill out applications faster, return an object that has the appropriate
 * `inputMode` and `autocomplete` attributes attached based on the type
 * */
export const getAutofillAttributes = (question: ProspectQuestion) => {
  let attrs: Record<string, any> = {};

  // the presence of an attribute name tells us if it's a default question like for
  // First name, last name, email, etc.
  const { attribute_name } = question;

  switch (question.answer_type) {
    case 'short_text':
    case 'long_text':
      if (attribute_name === 'first_name') {
        attrs.autoComplete = 'given-name';
      } else if (attribute_name === 'last_name') {
        attrs.autoComplete = 'family-name';
      }
      break;
    case 'email':
      attrs.autoComplete = 'email';
      attrs.inputMode = 'email';
      break;
    case 'phone_number':
      attrs.autoComplete = 'tel';
      attrs.inputMode = 'tel';
      break;
    case 'number':
      attrs.inputMode = 'numeric';
      break;
  }

  return attrs;
};

/**
 * @param {number} threshold - the amount in px to scroll above the error so the field doesn't
 *                             look like it is JUST shown at the top of the screen
 * */
export const scrollToFirstError = (
  errorSelector: string,
  threshold: number
) => {
  const errorFieldElem = document.querySelector(errorSelector);
  const offset = getOffset(errorFieldElem);

  window.scrollTo(0, offset.top - threshold);
};
