/**
 * Uses Google's implementation from the `android.util.Patterns` class.
 *
 * If it's good enough for Google, then it's good enough for us.
 * */
export const isValidEmail = (email: string) => {
  if (!email || email.trim().length === 0) {
    return false;
  }

  // eslint-disable-next-line max-len
  // @see: https://android.googlesource.com/platform/frameworks/base/+/oreo-release/core/java/android/util/Patterns.java#428
  // @see https://www.ietf.org/rfc/rfc2822.txt
  const EMAIL_PATTERN =
    '[a-zA-Z0-9\\+\\.\\_\\%\\-\\+]{1,256}' + // local-part
    '\\@' +
    // the domain
    '[a-zA-Z0-9][a-zA-Z0-9\\-]{0,64}' +
    '(' +
    '\\.' +
    '[a-zA-Z0-9][a-zA-Z0-9\\-]{0,25}' +
    ')+';

  const emailRegex = new RegExp(EMAIL_PATTERN);
  return emailRegex.test(email);
};
