import React from 'react';
// @ts-expect-error - TS7016 - Could not find a declaration file for module 'delve'. 'node_modules/delve/src/delve.js' implicitly has an 'any' type.
import delve from 'delve';
import styled from 'styled-components';


import { standardRequest, getErrorText } from 'shared/utils/request';

import { withLocalePrefix } from 'shared/utils/translation';

import {
  CardHeader,
  CardBody,
  // @ts-expect-error - TS7016 - Could not find a declaration file for module '@grnhse/seedling/lib/azalea/components/card'. 'node_modules/@grnhse/seedling/lib/azalea/components/card.js' implicitly has an 'any' type.
} from '@grnhse/seedling/lib/azalea/components/card';
// @ts-expect-error - TS7016 - Could not find a declaration file for module 'shared/DEPRECATED/components/Input'. 'app/webpack/javascripts/shared/DEPRECATED/components/Input.js' implicitly has an 'any' type.
import Input from 'shared/DEPRECATED/components/Input';
import Button, {
  ButtonProps,
} from '@grnhse/seedling/lib/azalea/components/button';
import FileUploader, {
  RejectionReason,
  // @ts-expect-error - TS7016 - Could not find a declaration file for module 'shared/components/files/FileUploader'. 'app/webpack/javascripts/shared/components/files/FileUploader.js' implicitly has an 'any' type.
} from 'shared/components/files/FileUploader';

import { XIcon, InformationIcon } from '@grnhse/seedling/lib/azalea/icons';
import {
  colors,
  padding,
  fontSize,
  fontWeight,
} from '@grnhse/seedling/lib/azalea/constants';

import type { EventType } from 'events/types';

const t = withLocalePrefix('events.upload_resume');

type Props = {
  resumeExists: boolean;
  event: EventType;
  organization: string;
  acceptedMimeTypes: Array<string>;
  maxFileSize: number;
};

type State = {
  resume: any | null;
  uploading: boolean;
  uploaded: boolean;
  error: string;
};

export default class UploadResumePage extends React.Component<Props, State> {
  // @ts-expect-error - TS2564 - Property 'fileInput' has no initializer and is not definitely assigned in the constructor.
  fileInput: HTMLInputElement;

  state = {
    resume: null,
    uploading: false,
    uploaded: false,
    error: '',
  };

  // turn this into a multipart file upload by returning a `window.FormData` object
  prepareUploadRequest = (requestParams: any) => {
    const { resume } = this.state;
    // @ts-expect-error - TS2358 - The left-hand side of an 'instanceof' expression must be of type 'any', an object type or a type parameter.
    let file = resume instanceof window.FileList ? resume[0] : resume;

    if (file === null) {
      return;
    }

    const formData = new FormData();
    formData.append('resume', file);

    for (var prop in requestParams) {
      if (requestParams.hasOwnProperty(prop)) {
        formData.append(prop, requestParams[prop]);
      }
    }

    return formData;
  };

  uploadResume() {
    this.setState({ uploading: true });

    const url = window.location.href;
    const uuid = window.location.search.substring(1).replace('uuid=', '');

    standardRequest
      .post(
        url,
        { uuid },
        {
          transformRequest: [this.prepareUploadRequest],
        }
      )
      .then(() => {
        this.setState({ uploading: false, uploaded: true, error: '' });
      })
      
      .catch((res) => {
        this.setState({
          uploading: false,
          error: getErrorText(delve(res, 'response.data') || {}),
        });
      });
  }

  openFilePicker = () => {
    this.fileInput.click();
  };

  clearSelectedFile = () => {
    this.setState({ resume: null });
  };

  onFileSelected = (fileList: any) => {
    this.setState({ resume: fileList });
  };

  isFileSelected = (fileList: any) => {
    return fileList && fileList.length > 0;
  };

  getFileName() {
    const { resume } = this.state;

    if (!this.isFileSelected(resume)) {
      return '';
    }

    // @ts-expect-error - TS2531 - Object is possibly 'null'.
    return resume[0].name;
  }

  getEventLabel() {
    const { event, organization } = this.props;
    const { name } = event;

    // if the event name already has the org name, then don't add the org name in quotes
    if (name && name.indexOf(organization) >= 0) {
      return name;
    }

    // otherwise, add it in case there are multiple GH customers in the same event
    return `${name} (${organization})`;
  }

  onFileSelectOrUpload = () => {
    if (this.isFileSelected(this.state.resume)) {
      this.uploadResume();
    } else {
      this.openFilePicker();
    }
  };

  onFilesRejected = (files: any, reason: string) => {
    if (reason === RejectionReason.MAX_SIZE_EXCEEDED) {
      this.setState({ error: t('errors.file_too_large') });

      setTimeout(() => {
        this.setState({ error: '' });
      }, 4000);
    }
  };

  render() {
    const { uploading, resume, error } = this.state;

    const {
      event,
      organization,
      acceptedMimeTypes,
      maxFileSize,
      resumeExists,
    } = this.props;

    const { logo_url } = event.event_configuration;

    const {
      welcome: requestedWelcomeMsg,
      thank_you: requestedConfirmationMsg,
    } = event;

    let fileSelected = this.isFileSelected(resume);
    let buttonText = fileSelected ? t('upload_file') : t('select_file');

    let welcomeMessage =
      requestedWelcomeMsg || t('resume_upload.not_uploaded.welcome');
    let confirmationMessage =
      requestedConfirmationMsg || t('resume_upload.not_uploaded.confirmation');

    return (
      <MainContainer>
        {logo_url && <Logo src={logo_url} />}

        {error && <Error>{error}</Error>}

        <ConstrainedCard>
          <CardHeader
            label={t('resume_upload.not_uploaded.header', {
              organization: organization,
            })}
          />

          <SpacedCardBody>
            {!this.state.uploaded && (
              <form action="" method="post">
                {resumeExists && (
                  <Notice>
                    <InformationIcon width="20px" height="20px" />
                    <span>{t('resume_upload.already_uploaded.details')}</span>
                  </Notice>
                )}

                <Description>{welcomeMessage}</Description>

                <FileInfoContainer>
                  <Input
                    type="text"
                    value={this.getFileName()}
                    disabled
                    block
                  />
                  {fileSelected && (
                    <ClearButton
                      className="clear-file"
                      onClick={this.clearSelectedFile}
                    >
                      <XIcon />
                    </ClearButton>
                  )}
                </FileInfoContainer>

                <UploadButton
                  className="submit-btn"
                  fancy={fileSelected}
                  onClick={this.onFileSelectOrUpload}
                  disabled={uploading}
                  block
                >
                  {buttonText}
                </UploadButton>

                <FileUploader
                  files={resume}
                  // @ts-expect-error - TS7006 - Parameter 'input' implicitly has an 'any' type.
                  fileInputRef={(input) => {
                    this.fileInput = input;
                  }}
                  maxSize={maxFileSize}
                  onChange={this.onFileSelected}
                  onFilesRejected={this.onFilesRejected}
                  acceptedMimeTypes={acceptedMimeTypes}
                  showPreview={false}
                  hidden
                />

                <Caption>{t('caption')}</Caption>
              </form>
            )}

            {this.state.uploaded && (
              <SuccessMessage>{confirmationMessage}</SuccessMessage>
            )}
          </SpacedCardBody>
        </ConstrainedCard>
      </MainContainer>
    );
  }
}

const MainContainer = styled.div`
  box-sizing: border-box;
  background: ${colors.lighterGrey};
  margin: 0 auto;

  padding-top: 50px;

  text-align: center;
`;

const Logo = styled.img`
  display: inline-block;
  width: 20vw;
  max-width: 100px;
  height: auto;
  margin: 0 auto;
`;

export const Error = styled.div`
  color: ${colors.darkRed};
  text-align: center;
  margin: 0 auto;
  padding: 0 ${padding.normal};
  margin: ${padding.normal} 0;

  ${fontWeight('heavy')}
`;

export const Description = styled.label`
  display: block;
  margin-bottom: ${padding.normal};

  overflow-wrap: break-word;
  word-wrap: break-word;

  font-size: ${fontSize.large};
  ${fontWeight('normal')};
  color: ${colors.darkestGrey};
`;

const ConstrainedCard = styled.div`
  margin: 0 auto;
  max-width: 500px;
  text-align: left;

  margin-top: ${padding.large};
`;

const SpacedCardBody = styled(CardBody)`
  padding: ${padding.normal} ${padding.large};
`;

const Caption = styled.label`
  display: block;

  color: ${colors.mediumGrey};
  font-size: ${fontSize.small};
  font-style: italic;

  margin-top: ${padding.small};
  margin-left: auto;
  margin-right: auto;
`;

// TODO: remove the !important after default app styles remove the overly
// specific style of "width: 200px"
export const FileInfoContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  position: relative;

  margin-right: ${padding.small} !important;

  @media (max-width: 400px) {
    width: 60% !important;
  }

  @media (min-width: 401px) {
    width: 75% !important;
  }
`;

export const UploadButton = styled(Button)<ButtonProps>`
  display: inline-block;
  vertical-align: middle;

  @media (max-width: 400px) {
    width: calc(100% - 60% - ${padding.small});
  }

  @media (min-width: 401px) {
    width: calc(100% - 75% - ${padding.small});
  }
`;

export const ClearButton = styled.span`
  position: absolute;
  right: ${padding.small};

  top: 50%;
  transform: translateY(-50%);
  height: 20px;
  width: 20px;

  &:hover {
    cursor: pointer;
  }
`;

export const SuccessMessage = styled(Description)`
  margin-bottom: 0;
`;

const iconRightMargin = '5px';

export const Notice = styled.div`
  margin-bottom: ${padding.normal};

  svg,
  span {
    vertical-align: text-top;
    display: inline-block;
  }

  svg {
    margin: 0;
    margin-right: ${iconRightMargin};
  }

  span {
    width: calc(100% - 20px - ${iconRightMargin});
  }
`;
