// @ts-expect-error - TS7016 - Could not find a declaration file for module 'google-libphonenumber'. 'node_modules/google-libphonenumber/dist/libphonenumber.js' implicitly has an 'any' type.
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';
import _ from 'underscore';

const phoneNumberUtil = PhoneNumberUtil.getInstance();

export const getAllCountryCodes = (): Array<number> => {
  const sortedCodes = phoneNumberUtil
    .getSupportedRegions()
    .map((region: string) => {
      return phoneNumberUtil.getCountryCodeForRegion(region);
    })
    .sort((codeA: number, codeB: number) => {
      if (codeA > codeB) {
        return 1;
      }

      if (codeA < codeB) {
        return -1;
      }

      return 0;
    });

  // dedup the codes as there are multiple countries with the same country prefix
  return _.uniq(sortedCodes, true);
};

export const isValidNumber = (
  countryCode: number,
  numberStr: number | string
): boolean => {
  const regionCode = phoneNumberUtil.getRegionCodeForCountryCode(countryCode);

  try {
    return phoneNumberUtil.isValidNumber(
      phoneNumberUtil.parse(numberStr, regionCode)
    );
  } catch (e: any) {
    return false;
  }
};

export const isValidInternationalNumber = (numberStr: string): boolean => {
  try {
    return phoneNumberUtil.isValidNumber(phoneNumberUtil.parse(numberStr));
  } catch (e: any) {
    return false;
  }
};

export const format = (
  countryCode: number,
  numberStr: string,
  includeCountryCode: boolean = false
): string => {
  const regionCode = phoneNumberUtil.getRegionCodeForCountryCode(countryCode);
  let formattedVal = numberStr;
  let numberFormat = includeCountryCode
    ? PhoneNumberFormat.INTERNATIONAL
    : PhoneNumberFormat.NATIONAL;

  // need a `PhoneNumber` object for format to work
  try {
    const phoneNumber = phoneNumberUtil.parse(formattedVal, regionCode);
    formattedVal = phoneNumberUtil.format(phoneNumber, numberFormat);
  } catch (e: any) {
    // the user probably hasn't finished entering the full phone number and thus the lib threw
    // a parsing error. Just ignore for now
  }
  return formattedVal;
};
