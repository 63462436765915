export const buildQuestionRequest = (question, value) => {
  return {
    ...question,
    answers: value,
  };
};

export const buildMultiSelectQuestionRequest = (question, optionIds) => {
  if (!question || !optionIds) {
    return null;
  }

  let questionOptions = optionIds
    .map((qoId) => {
      const qos = question.question_options || [];
      const questionOption = qos.filter((qo) => qo.id === qoId)[0];

      // the prospect question answer only has 1 attribute: `value`
      // everything else is unnecessary
      return questionOption ? { value: questionOption.label } : null;

      // to filter out null or undefined values
    })
    .filter((qo) => !!qo);

  return buildQuestionRequest(question, questionOptions);
};

export const buildSingleSelectQuestionRequest = (question, optionId) => {
  return buildMultiSelectQuestionRequest(question, [optionId]);
};
