/* global __DEV__:false */

import React from 'react';
import type { ReactNode } from 'react';
import ProspectQuestion from 'events/models/prospect_question';

import styled from 'styled-components';
import classNames from 'classnames';
// @ts-expect-error - TS7016 - Could not find a declaration file for module 'shared/DEPRECATED/components/Input'. 'app/webpack/javascripts/shared/DEPRECATED/components/Input.js' implicitly has an 'any' type.
import Input from 'shared/DEPRECATED/components/Input';
import TextArea from 'shared/components/TextArea';
import NativeInternationalPhoneInput from 'shared/components/phone/NativeInternationalPhoneInput';
import type { PhoneNumber } from 'shared/components/phone/types';
// @ts-expect-error - TS7016 - Could not find a declaration file for module './styles'. 'app/webpack/javascripts/events/prospects/components/styles.js' implicitly has an 'any' type.
import { BlockLabel } from './styles';
import _ from 'underscore';
// @ts-expect-error - TS7016 - Could not find a declaration file for module 'shared/components/web_or_mobile_dropdown'. 'app/webpack/javascripts/shared/components/web_or_mobile_dropdown.js' implicitly has an 'any' type.
import WebOrMobileDropdown from 'shared/components/web_or_mobile_dropdown';

import {
  colors,
  padding,
  fontSize,
  fontWeight,
} from '@grnhse/seedling/lib/azalea/constants';

import { getAutofillAttributes } from '../form_helper';
import { deserialize } from '../deserialize_utils';

type Props = {
  question: ProspectQuestion;
  value: unknown | null;
  onChange: (value: unknown) => unknown;
  // really only used for dropdowns. All other controls are native HTML controls and so
  // are touch friendly by default.
  touchFriendly: boolean;
  // to indicate to this component that the value currently is invalid and to show an error state
  error: string | null;
};

type QuestionSelectOption = {
  value: string;
  label: string;
};

export const FIELD_CONTAINER_CLASS_NAME = 'field-group';
export const ERROR_CLASS_NAME = 'error';
const FIELD_CLASS_NAME = 'prospect-question-field';

export default class ProspectQuestionField extends React.PureComponent<Props> {
  /**
   * React select gives an array if it's multi-select and the plain object if it's not.
   * Convert it to an array always for easier handling
   * */
  onTextInputChange = (evt: React.SyntheticEvent<HTMLInputElement>) => {
    const { question } = this.props;

    this.props.onChange(deserialize(question, evt.currentTarget.value));
  };

  onPhoneInputChange = (number: PhoneNumber) => {
    const { question } = this.props;
    // @ts-expect-error - TS2345 - Argument of type 'PhoneNumber' is not assignable to parameter of type 'string | string[]'.
    this.props.onChange(deserialize(question, number));
  };

  renderDropdown(multiSelect: boolean = false): ReactNode {
    const { question, touchFriendly, onChange, value } = this.props;
    return (
      <WebOrMobileDropdown
        multiSelect={multiSelect}
        className={FIELD_CLASS_NAME}
        question={question}
        touchFriendly={touchFriendly}
        onChange={onChange}
        value={value}
      />
    );
  }

  render() {
    const { question, error } = this.props;
    const { value: questionLabel, answer_type, required } = question;

    // input elements need to be set to a string, not null so default their value to an empty
    // string
    const value = this.props.value ? this.props.value : '';

    let fieldComponent;
    let autofillAttrs = getAutofillAttributes(question);
    switch (answer_type) {
      case 'short_text':
        fieldComponent = (
          <Input
            className={FIELD_CLASS_NAME}
            type="text"
            onChange={this.onTextInputChange}
            value={value}
            block
            {...autofillAttrs}
          />
        );
        break;
      case 'long_text':
        fieldComponent = (
          <TextArea
            // @ts-expect-error - TS2769 - No overload matches this call.
            className={FIELD_CLASS_NAME}
            rows="5"
            onChange={this.onTextInputChange}
            value={value}
            block
            {...autofillAttrs}
          />
        );
        break;
      case 'email':
        fieldComponent = (
          <Input
            className={FIELD_CLASS_NAME}
            type="email"
            onChange={this.onTextInputChange}
            value={value}
            block
            {...autofillAttrs}
          />
        );
        break;
      case 'phone_number':
        fieldComponent = (
          <NativeInternationalPhoneInput
            childClassName={FIELD_CLASS_NAME}
            onChange={this.onPhoneInputChange}
            // $FlowFixMe
            // @ts-expect-error - TS2322 - Type 'unknown' is not assignable to type 'PhoneNumber | null'.
            value={value}
            {...autofillAttrs}
          />
        );
        break;
      case 'number':
        fieldComponent = (
          <Input
            className={FIELD_CLASS_NAME}
            type="number"
            onChange={this.onTextInputChange}
            value={value}
            block
            {...autofillAttrs}
          />
        );
        break;
      case 'single_select':
        fieldComponent = this.renderDropdown(false);
        break;
      case 'multi_select':
        fieldComponent = this.renderDropdown(true);
        break;
    }

    return (
      <FieldGroup
        className={classNames(FIELD_CONTAINER_CLASS_NAME, { error: error })}
      >
        <BlockLabel>
          {questionLabel}
          {required && <RequiredAsterisk>&nbsp;*</RequiredAsterisk>}
        </BlockLabel>
        {fieldComponent}
        {error && <Error>{error}</Error>}
      </FieldGroup>
    );
  }
}

const FieldGroup = styled.div`
  & + & {
    margin-top: ${padding.normal};
  }

  label {
    // to add spacing between the label and the input
    margin-bottom: ${padding.tiny};
  }

  input,
  textarea,
  .dropdown-wrapper {
    border-radius: 3px;

    .dropdown-wrapper:not(.multiple) {
      height: 45px;
    }

    .dropdown {
      // set the border radius of the select as well as the wrapper to make sure it renders
      // correctly
      border-radius: 3px;
    }

    .dropdown-indicator {
      right: 10px; // move it a little more away from the edge so it looks nicer
    }
  }

  input,
  textarea,
  .dropdown {
    padding: ${padding.small};
    font-size: ${fontSize.large};

    // to target the react-select input cursor to not be offset
    &[role='combobox'] {
      padding-left: 0;
    }
  }

  &&&.${ERROR_CLASS_NAME}
    .${FIELD_CLASS_NAME},
    &&&.${ERROR_CLASS_NAME}
    .dropdown-wrapper {
    // TODO: remove the !important after base style is replaced to use &&& instead
    border: 1px solid ${colors.darkRed} !important;
  }
`;

const RequiredAsterisk = styled.span`
  color: ${colors.darkRed};
`;

export const Error = styled.div`
  margin-bottom: ${padding.small};
  color: ${colors.darkRed};
  ${fontWeight('heavy')};
`;
