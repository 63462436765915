import styled from 'styled-components';
import {
  colors,
  padding,
  fontSize,
  fontWeight,
} from '@grnhse/seedling/lib/azalea/constants';

const LOGO_HEIGHT = '80px';
export const MAX_FORM_WIDTH = '600px';
export const MAIN_CONTAINER_PADDING = '40px';

export const BlockLabel = styled.label`
  display: block;
  color: ${colors.darkGrey};
  ${fontWeight('heavy')};

  margin-bottom: ${padding.small};
`;

export const Error = styled.div`
  color: ${colors.darkRed};
  text-align: center;
  margin: 0 auto;
  padding: 0 ${padding.normal};
  margin: ${padding.normal} 0;

  ${fontWeight('heavy')}
`;

export const Header = styled.div`
  ${(props) =>
    props.center &&
    `
    text-align: center;
  `}

  ${fontWeight('heaviest')};
  font-size: ${fontSize.huge};

  margin-bottom: ${padding.small};
`;

export const LogoContainer = styled.div`
  text-align: center;
  margin-bottom: ${padding.normal};

  img {
    max-height: ${LOGO_HEIGHT};
    max-width: calc(${MAX_FORM_WIDTH} - (2 * ${MAIN_CONTAINER_PADDING}));
    width: auto;
  }
`;

export const Description = styled.div`
  font-size: ${fontSize.large};
  line-height: ${fontSize.veryLarge};

  white-space: pre-line;
  color: ${colors.darkGrey};
`;

export const MainFormContainer = styled.div`
  box-sizing: border-box;
  max-width: ${MAX_FORM_WIDTH};
  border: 1px solid ${colors.lightGrey};

  background: ${colors.white};
  padding: ${MAIN_CONTAINER_PADDING};

  @media (min-width: 601px) {
    // add spacing from the top and bottom of the page as soon as the max-width is exceeded
    margin: ${padding.large} auto;
  }
`;
