import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
// @ts-expect-error - TS7016 - Could not find a declaration file for module 'shared/DEPRECATED/components/Input'. 'app/webpack/javascripts/shared/DEPRECATED/components/Input.js' implicitly has an 'any' type.
import Input from 'shared/DEPRECATED/components/Input';
import Dropdown from 'shared/components/select_components/native_styled_dropdown';

import {
  getAllCountryCodes,
  format,
  isValidNumber,
} from 'shared/utils/phoneUtil';

import { padding } from '@grnhse/seedling/lib/azalea/constants';
import type { PhoneNumber } from './types';

type Props = {
  className?: string; // standard className attached to root,
  childClassName?: string; // className attached to the 2 individual child components,
  value: PhoneNumber | null;
  onChange: (arg1: PhoneNumber) => unknown;
  required?: boolean;
};

type State = {
  countryCodes: Array<number>;
};

// default to US
const DEFAULT_COUNTRY_CODE = 1;

export default class NativeInternationalPhoneInput extends React.PureComponent<
  Props,
  State
> {
  static defaultProps = {
    required: false,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      countryCodes: getAllCountryCodes(),
    };
  }

  onCountryCodeChange = (evt: React.SyntheticEvent<HTMLSelectElement>) => {
    const countryCode = evt.currentTarget.value;
    const number = this.props.value ? this.props.value.number : '';

    this.props.onChange({
      // $FlowFixMe
      // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'number'.
      countryCode: countryCode,
      number: number,
      formattedPhoneNumber: format(
        parseInt(countryCode, 10),
        number.toString()
      ),
      internationalFormattedPhoneNumber: format(
        parseInt(countryCode, 10),
        number.toString(),
        true
      ),
      valid: isValidNumber(parseInt(countryCode, 10), number),
    });
  };

  onPhoneNumberChange = (evt: React.SyntheticEvent<HTMLInputElement>) => {
    const countryCode = this.props.value
      ? this.props.value.countryCode
      : DEFAULT_COUNTRY_CODE;
    const number = evt.currentTarget.value;

    this.props.onChange({
      countryCode: countryCode,
      number: number,
      formattedPhoneNumber: format(countryCode, number.toString()),
      internationalFormattedPhoneNumber: format(
        countryCode,
        number.toString(),
        true
      ),
      // @ts-expect-error - TS2345 - Argument of type 'number' is not assignable to parameter of type 'string'.
      valid: isValidNumber(parseInt(countryCode, 10), number),
    });
  };

  render() {
    const { className, childClassName, required, value } = this.props;
    const { countryCodes } = this.state;

    // default to US country code
    const countryCode = value ? value.countryCode : DEFAULT_COUNTRY_CODE;
    const formattedNumber = value ? value.formattedPhoneNumber : '';

    return (
      <div className={className}>
        <CountryCodeDropdown
          className={classNames('country-code', childClassName)}
          value={countryCode}
          required={required}
          onChange={this.onCountryCodeChange}
        >
          {countryCodes.map((code, idx) => (
            <option value={code} key={idx}>
              +{code}
            </option>
          ))}
        </CountryCodeDropdown>
        <PhoneNumberInput
          className={classNames('phone-input', childClassName)}
          onChange={this.onPhoneNumberChange}
          value={formattedNumber}
        />
      </div>
    );
  }
}

const componentHeight = '45px';
const countryCodeDropdownWidth = '75px';

const CountryCodeDropdown = styled(Dropdown)`
  display: inline-block;
  width: ${countryCodeDropdownWidth};
  vertical-align: middle;
  height: ${componentHeight};
`;

const PhoneNumberInput = styled(Input).attrs({
  type: 'tel',
  autoComplete: 'tel',
  inputMode: 'tel',
})`
  height: ${componentHeight};
  vertical-align: middle;

  margin-left: ${padding.small};
  width: calc(100% - ${padding.small} - ${countryCodeDropdownWidth});
`;
