import { isValidEmail } from 'shared/utils/validators';
import { isValidInternationalNumber } from 'shared/utils/phoneUtil';
import { withLocalePrefix } from 'shared/utils/translation';
const t = withLocalePrefix('events.new_prospect_application');

type ProspectQuestionType =
  | 'short_text'
  | 'long_text'
  | 'single_select'
  | 'multi_select'
  | 'email'
  | 'phone_number'
  | 'number';

export type ProspectQuestionOption = {
  id: number;
  label: string;
  priority: number;
};

export type ProspectQuestionServerType = {
  id: number;
  answer_type: ProspectQuestionType;
  value: string;
  required: boolean;
  question_options?: Array<ProspectQuestionOption>;
  // used to indicate whether it directly maps to a model attribute (i.e first_name,
  // last_name, etc.)
  attribute_name?: string | null | undefined;
};

type ValidState = {
  valid: boolean;
  error: string | null | undefined;
};

export default class ProspectQuestion {
  // @ts-expect-error - TS2564 - Property 'id' has no initializer and is not definitely assigned in the constructor.
  id: number;
  // @ts-expect-error - TS2564 - Property 'answer_type' has no initializer and is not definitely assigned in the constructor.
  answer_type: ProspectQuestionType;
  // @ts-expect-error - TS2564 - Property 'value' has no initializer and is not definitely assigned in the constructor.
  value: string;
  // @ts-expect-error - TS2564 - Property 'required' has no initializer and is not definitely assigned in the constructor.
  required: boolean;
  // @ts-expect-error - TS2564 - Property 'question_options' has no initializer and is not definitely assigned in the constructor.
  question_options: Array<ProspectQuestionOption>;
  attribute_name: string | null | undefined;

  constructor(obj: ProspectQuestionServerType) {
    Object.assign(this, { attribute_name: null, question_options: [] }, obj);
  }

  isSingleSelectQuestion() {
    return this.answer_type === 'single_select';
  }

  isMultiSelectQuestion() {
    return this.answer_type === 'multi_select';
  }

  isNumericQuestion() {
    return this.answer_type === 'number';
  }

  isPhoneNumberQuestion() {
    return this.answer_type === 'phone_number';
  }

  isEmailQuestion() {
    return this.answer_type === 'email';
  }

  isValid(value: unknown): ValidState {
    // single/multi-select fields are treated a little differently so check for those first
    if (this.isSingleSelectQuestion()) {
      const isPlaceholderOptionValue =
        value === null || typeof value === 'undefined' || value === -1;

      if (this.required && isPlaceholderOptionValue) {
        // an invalid option would be one that has value option set to -1 or is null
        return { valid: false, error: t('errors.required_field') };
      }
    } else if (this.isMultiSelectQuestion()) {
      const hasSelectedNoOptions =
        typeof value === 'undefined' ||
        (value instanceof Array && value.length === 0);
      const hasEnteredInvalidValue =
        typeof value !== 'undefined' && !(value instanceof Array);

      if ((this.required && hasSelectedNoOptions) || hasEnteredInvalidValue) {
        return { valid: false, error: t('errors.required_field') };
      }
    } else {
      if (this.isPhoneNumberQuestion()) {
        let phoneNumberStr = value;

        // if this is an object, it might be a PhoneNumber type so dig into it.
        // @see shared/components/phone/types.js
        if (typeof value === 'object' && value !== null) {
          // @ts-expect-error - TS2339 - Property 'internationalFormattedPhoneNumber' does not exist on type 'object'.
          phoneNumberStr = value.internationalFormattedPhoneNumber;
        }

        // number isn't supported because international country code with the `+` prefix is
        // required
        const isEmptyValue =
          typeof phoneNumberStr === 'undefined' ||
          phoneNumberStr === null ||
          (typeof phoneNumberStr === 'string' && phoneNumberStr.length === 0);

        if (
          (isEmptyValue && this.required) ||
          (typeof phoneNumberStr === 'string' &&
            !isValidInternationalNumber(phoneNumberStr))
        ) {
          return { valid: false, error: t('errors.invalid_phone_number') };
        }
      } else if (this.isEmailQuestion()) {
        const needsValidation = this.required || typeof value !== 'undefined';

        if (
          needsValidation &&
          (typeof value !== 'string' ||
            (typeof value === 'string' && !isValidEmail(value)))
        ) {
          return { valid: false, error: t('errors.invalid_email') };
        }
      } else if (this.isNumericQuestion()) {
        if (this.required && Number.isNaN(value)) {
          return { valid: false, error: t('errors.invalid_numeric_number') };
        }
      }
      // if it's any other field, it must be a plain text based field so just do a required
      // check
      else if (this.required) {
        if (typeof value !== 'string' || value.trim().length === 0) {
          return { valid: false, error: t('errors.required_field') };
        } else if (typeof value === 'undefined') {
          return { valid: false, error: t('errors.required_field') };
        }
      }
    }

    return {
      valid: true,
      error: null,
    };
  }
}
