import React from 'react';
import styled from 'styled-components';
import { colors, padding } from '@grnhse/seedling/lib/azalea/constants';

interface FileInfoContainerProps {
  selectedFilesText: string;
  removeFilesText: string;
  onRemoveLinkClick: () => void;
  filesErrorMessage?: string;
}

const FileInfoContainer = ({ selectedFilesText, removeFilesText, onRemoveLinkClick, filesErrorMessage }: FileInfoContainerProps) => (
  <Container className="file-info-container">
    <span className="selected-files-label">{selectedFilesText}</span>
    &nbsp;|&nbsp;
    <RemoveFilesLink onClick={onRemoveLinkClick}>
      {removeFilesText}
    </RemoveFilesLink>
    {filesErrorMessage && <ErrorMessage data-provides={'files_error_message'}>{filesErrorMessage}</ErrorMessage>}
  </Container>
);

export default FileInfoContainer;

const Container = styled.div`
  margin: ${padding.tiny};
  margin-bottom: ${padding.small};
  text-align: start;
`;

const RemoveFilesLink = styled.a`
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  color: ${colors.darkRed};
`;

const ErrorMessage = styled.div`
  font-size: 14px;
  color: ${colors.darkRed};
  margin-top: ${padding.tiny};
  max-width: 75%;
  white-space: pre;
`;
