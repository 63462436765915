import styled from 'styled-components';

export const SplitPane = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

export const InputControlsPane = styled(SplitPane)`
  text-align: center;
`;
