import React from 'react';
import PropTypes from 'prop-types';

import uuid from 'uuid';
import s from 'styled-components';

import Button from '@grnhse/seedling/lib/azalea/components/button';
import FilePreview, {
  ITEM_WIDTH,
  ITEM_MARGIN_HORIZONTAL,
} from 'shared/components/files/FilePreview';
import FileInfoContainer from './FileInfoContainer';
import t from 'shared/utils/translation';
import { SplitPane, InputControlsPane } from './constants';

export const RejectionReason = {
  MAX_SIZE_EXCEEDED: 'too_large',
};

export default class FileUploader extends React.Component {
  static propTypes = {
    // name of the form field name
    name: PropTypes.string,

    multiple: PropTypes.bool,
    acceptedMimeTypes: PropTypes.array,
    showPreview: PropTypes.bool,
    maxSize: PropTypes.number,

    onChange: PropTypes.func,
    onFilesRejected: PropTypes.func,

    removeFilesText: PropTypes.string,
    filesErrorMessage: PropTypes.string,

    // enabling this means that previews, the selection button and more are not rendered
    // instead, ONLY the "hidden" file input is rendered.
    // The caller sets up their own UI and manually triggers
    // the file selection instead of relying on this component's default UI
    hidden: PropTypes.bool,

    files: PropTypes.oneOfType([
      PropTypes.instanceOf(window.FileList),

      // used mostly for testing.
      // recommended to pass `FileList`
      PropTypes.arrayOf(PropTypes.object),
    ]),

    fileInputRef: PropTypes.func,
  };

  static defaultProps = {
    name: '',
    multiple: false,
    acceptedMimeTypes: [],
    showPreview: false,
    maxSize: 1000 * 1000 * 50, // 50mb
    onChange: () => {},
    onFilesRejected: () => {},
    removeFilesText: t('shared.file_uploader.remove'),
    filesErrorMessage: undefined,
    hidden: false,
    files: null,
    fileInputRef: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      id: uuid.v4(),
      fileInputKey: this.generateFileInputKey(),
    };
  }

  componentDidUpdate(prevProps) {
    // Initial page load had no files, no need to generate a new key
    if (!prevProps.files) {
      return;
    }

    // When the files property is cleared, the key is
    // regenerated so that React is tricked into regenerating a new instance of that component
    // which is the easiest, cross-browser way of clearing the form input without wrapping this
    // component in an arbitrary form and calling reset();
    if (prevProps.files !== this.props.files) {
      this.setState({ fileInputKey: this.generateFileInputKey() });
    }
  }

  generateFileInputKey = () => {
    return Date.now();
  };

  onFilesSelected = (evt) => {
    const elem = evt.target;

    // if the total size of the files exceed the acceptable size, prevent the user
    // action
    if (this.getTotalSize(elem.files) > this.props.maxSize) {
      evt.preventDefault();
      this.props.onFilesRejected(elem.files, RejectionReason.MAX_SIZE_EXCEEDED);
      return;
    }

    // if the user clicked cancel on the file picker, then they would get `files`
    // with length = 0 but if something is already selected, use that instead
    const files = elem.files.length > 0 ? elem.files : this.props.files;
    this.props.onChange(files);
  };

  onFilesCleared = () => {
    this.props.onChange(null);
  };

  getTotalSize(files) {
    // can't use reduce() since `FileList` isn't an actual array :/
    var sum = 0;
    for (var i = 0; i < files.length; i++) {
      sum += files[i].size;
    }

    return sum;
  }

  renderFilePreviews() {
    if (!this.props.files || this.props.files.length === 0) {
      return;
    }

    let previews = [];
    for (var i = 0; i < this.props.files.length; i++) {
      previews.push(<FilePreview key={i} file={this.props.files[i]} />);
    }

    return (
      <FilesPreviewContainer data-provides="files-preview-container">
        {previews}
      </FilesPreviewContainer>
    );
  }

  render() {
    const { files, children, hidden, acceptedMimeTypes } = this.props;
    let buttonText = children ? children : t('shared.file_uploader.select');
    let selectedFileText = null;
    let hasFiles = false;

    if (files && files.length > 0) {
      if (files.length > 1) {
        selectedFileText = t('shared.file_uploader.selected', {
          count: files.length,
        });
      } else {
        selectedFileText = files[0].name;
      }
      hasFiles = true;
    }

    const acceptedMimeTypesStr = acceptedMimeTypes.join();

    return (
      <Container>
        {this.props.showPreview && !hidden && this.renderFilePreviews()}

        <InputControlsPane>
          {hasFiles && !hidden && (
            <FileInfoContainer
              selectedFilesText={selectedFileText}
              onRemoveLinkClick={this.onFilesCleared}
              removeFilesText={this.props.removeFilesText}
              filesErrorMessage={this.props.filesErrorMessage}
            />
          )}

          {!hidden && !hasFiles && (
            <ProxyFileInput htmlFor={this.state.id}>
              <UploadButton block>{buttonText}</UploadButton>
            </ProxyFileInput>
          )}

          <SneakyInput
            type="file"
            key={this.state.fileInputKey}
            name={this.props.name}
            accept={acceptedMimeTypesStr}
            multiple={this.props.multiple}
            size={this.props.maxSize}
            id={this.state.id}
            ref={this.props.fileInputRef}
            onChange={this.onFilesSelected}
          />
        </InputControlsPane>
      </Container>
    );
  }
}

const Container = s.div`
  display: inline-block;
`;

// for now keep things simple
// the max-width of this preview pane will fit 2 images before wrapping
const ITEMS_PER_COLUMN = 2;
export const FilesPreviewContainer = s(SplitPane)`
  max-width: calc((${ITEM_WIDTH} * ${ITEMS_PER_COLUMN}) + (${ITEM_MARGIN_HORIZONTAL} * 2 * ${ITEMS_PER_COLUMN}));
`;

// this will be the label that acts as a proxy for the real file input
// an approximation of most browsers outline when
// focused via tabbing
const blueOutline = '#5E9ED6';

/**
 * Real HTML file inputs cannot be styled well.
 *
 * To get around this, A label is created that sends a click event to the
 * real HTML file input (one that is hidden from view) so that it behaves
 * like a regular file input.
 * */
const ProxyFileInput = s.label`
  display: inline-block;
  width: 200px;
  margin: 0 auto;

  &:focus {
    outline: 1px dotted ${blueOutline};
    outline: 5px auto -webkit-focus-ring-color;
  }
`;

// make the file input as inconspicuous as possible
// but still technically visible to the browser so that form submits work properly
export const SneakyInput = s.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

export const UploadButton = s(Button)`
  overflow-wrap: break-word;
`;
