/* global __DEV__: false */

import React from 'react';
// @ts-expect-error - TS7016 - Could not find a declaration file for module 'shared/components/files/FileUploader'. 'app/webpack/javascripts/shared/components/files/FileUploader.js' implicitly has an 'any' type.
import { RejectionReason } from 'shared/components/files/FileUploader';
import _ from 'underscore';
import generateUuid from 'uuid';
// @ts-expect-error - TS7016 - Could not find a declaration file for module 'delve'. 'node_modules/delve/src/delve.js' implicitly has an 'any' type.
import delve from 'delve';
import styled from 'styled-components';
import { colors, padding } from '@grnhse/seedling/lib/azalea/constants';

import { standardRequest, getErrorText } from 'shared/utils/request';

// @ts-expect-error - TS7016 - Could not find a declaration file for module 'shared/utils/touch_detection'. 'app/webpack/javascripts/shared/utils/touch_detection.js' implicitly has an 'any' type.
import { canAssumeTouchDevice } from 'shared/utils/touch_detection';

import type { EventType } from 'events/types';
import type { NewProspectRequest } from '../types';
import type { ProspectQuestionServerType } from 'events/models/prospect_question';
import type { PhoneNumber } from 'shared/components/phone/types';
import ProspectQuestion from 'events/models/prospect_question';

import ProspectQuestionField, {
  FIELD_CONTAINER_CLASS_NAME,
  ERROR_CLASS_NAME,
} from './prospect_question_field';

import Button from '@grnhse/seedling/lib/azalea/components/button';
// @ts-expect-error - TS7016 - Could not find a declaration file for module 'shared/components/files/FileUploader'. 'app/webpack/javascripts/shared/components/files/FileUploader.js' implicitly has an 'any' type.
import FileUploader from 'shared/components/files/FileUploader';
import FileInfoContainer from 'shared/components/files/FileInfoContainer';
import { FileIcon } from '@grnhse/seedling/lib/azalea/icons';
import {
  Header,
  BlockLabel,
  Error,
  LogoContainer,
  MainFormContainer,
  MAX_FORM_WIDTH,
  MAIN_CONTAINER_PADDING,
  // @ts-expect-error - TS7016 - Could not find a declaration file for module './styles'. 'app/webpack/javascripts/events/prospects/components/styles.js' implicitly has an 'any' type.
} from './styles';

import { scrollToFirstError } from '../form_helper';

import {
  buildQuestionRequest,
  buildMultiSelectQuestionRequest,
  buildSingleSelectQuestionRequest,
  // @ts-expect-error - TS7016 - Could not find a declaration file for module '../request_builder'. 'app/webpack/javascripts/events/prospects/request_builder.js' implicitly has an 'any' type.
} from '../request_builder';

import { getAsFormData } from 'shared/utils/formUtils';
// @ts-expect-error - TS7016 - Could not find a declaration file for module 'shared/utils/positionUtils'. 'app/webpack/javascripts/shared/utils/positionUtils.js' implicitly has an 'any' type.
import { getOffset } from 'shared/utils/positionUtils';

import { withLocalePrefix } from 'shared/utils/translation';

// used to position the window a little above the error element just so it's not JUST
// showing the content on the top of the screen
const ERROR_THRESHOLD = 20;

export type Props = {
  event: EventType;
  maxFileSize: number;
  acceptedMimeTypes: Array<string>;
  submissionUrl: string;
};

type FormFieldState = {
  error: string;
  value: string | Array<number> | number | PhoneNumber;
};

type State = {
  uuid: string;
  // used for holding onto the state of the form.
  answers: {
    [question_id: number]: FormFieldState;
  };
  file: FileList | null;
  // are we in the process of submitting?
  submitting: boolean;
  // have we submitted successfully?
  submitted: boolean;
  error: string;
  // used for rendering the right controls for the best UX
  touchFriendly: boolean;
};

const canAssumeMobileDevice = () => {
  return window.innerWidth <= 500;
};

const t = withLocalePrefix('events.new_prospect_application');
const uploadT = withLocalePrefix('events.upload_resume');

export default class NewProspectForm extends React.Component<Props, State> {
  fileInput: HTMLInputElement | null;
  errorElement: HTMLDivElement | null;

  constructor(props: Props) {
    super(props);

    // each prospect application requires a unique uuid and the client is responsible
    // for generating it.
    const prospectId = generateUuid().toUpperCase();

    this.fileInput = null;
    this.errorElement = null;

    this.state = {
      uuid: prospectId,
      answers: this.getInitialFormState(),
      file: null,
      submitting: false,
      submitted: false,
      error: '',
      touchFriendly: false,
    };
  }

  getInitialFormState() {
    // populate the answers with an initial form state
    const answers: Record<string, any> = {};

    const { event_configuration } = this.props.event;

    event_configuration.questions.forEach((question) => {
      const id = question.id;

      // mark all as valid for now by setting error to empty string
      answers[id] = { error: '' };
    });

    return answers;
  }

  UNSAFE_componentWillMount() {
    // guess if it's touch screen. If so, then set the flag. This is used to ensure the right
    // components are used for rendering the right field.
    this.setState({
      touchFriendly: canAssumeTouchDevice() || canAssumeMobileDevice(),
    });
  }

  getQuestions = (event: EventType) => {
    return event.event_configuration.questions;
  };

  getQuestion = (event: EventType, questionId: number) => {
    return _(this.getQuestions(event)).findWhere({ id: questionId });
  };

  onAnswerChange = (questionId: number, value: unknown) => {
    const { event } = this.props;
    let question = this.getQuestion(event, questionId);
    const newAnswers = { ...this.state.answers } as const;

    // if the question was not found, then there's an issue with the code
    if (!question) {
      if (__DEV__) {
        // eslint-disable-next-line no-console
        console.warn(
          'The question for which this change is supposed to be tied to was NOT found!'
        );
      }
      return;
    }

    // convert it to a prospect question instance for access to the utility methods.
    question = new ProspectQuestion(question);

    // @ts-expect-error - TS2339 - Property 'isSingleSelectQuestion' does not exist on type 'ProspectQuestionServerType'.
    if (question.isSingleSelectQuestion()) {
      // update the value to only store the option id (since the options value is always
      // returned as a [] to keep dropdown value formats consistent, just unwrap.
      // @ts-expect-error - TS2571 - Object is of type 'unknown'. | TS2571 - Object is of type 'unknown'.
      newAnswers[questionId].value = value[0] ? value[0].id : null;
      // @ts-expect-error - TS2339 - Property 'isMultiSelectQuestion' does not exist on type 'ProspectQuestionServerType'.
    } else if (question.isMultiSelectQuestion()) {
      // update the value to store all the selected option ids
      // @ts-expect-error - TS2571 - Object is of type 'unknown'. | TS7006 - Parameter 'option' implicitly has an 'any' type.
      newAnswers[questionId].value = value.map((option) => option.id);
    } else {
      // update the value
      // @ts-expect-error - TS2322 - Type 'unknown' is not assignable to type 'string | number | number[] | PhoneNumber'.
      newAnswers[questionId].value = value;
    }

    this.setState({ answers: newAnswers });
  };

  onSelectResumeClick = () => {
    if (!this.fileInput) {
      return;
    }

    // open file picker
    this.fileInput.click();
  };

  onResumeSelected = (file: FileList) => {
    this.setState({ file: file });

    setTimeout(() => {
      this.setState({ error: '' });
    }, 4000);
  };

  onResumeRejected = (files: any, reason: RejectionReason) => {
    if (reason === RejectionReason.MAX_SIZE_EXCEEDED) {
      this.setState({ error: uploadT('errors.file_too_large') });

      setTimeout(() => {
        this.setState({ error: '' });
      }, 4000);
    }
  };

  onResumeRemoved = () => {
    this.setState({ file: null });
  };

  transformToMultipartFormData = (requestParams: any) => {
    return getAsFormData(requestParams);
  };

  prepareSubmissionRequest = (): NewProspectRequest => {
    const { answers, file } = this.state;
    const { id: event_id } = this.props.event;

    // convert stored answers to the format needed
    let questionRequests = Object.keys(answers).map((questionId) => {
      // need to do a parseInt as the integer based keys get converted to string when
      // Object.keys() is done
      // @ts-expect-error - TS2322 - Type 'number' is not assignable to type 'string'.
      questionId = parseInt(questionId, 10);

      // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'number'.
      let question = this.getQuestion(this.props.event, questionId);
      let value = answers[parseInt(questionId, 10)].value;

      if (!question) {
        return null;
      }

      question = new ProspectQuestion(question);

      // @ts-expect-error - TS2339 - Property 'isSingleSelectQuestion' does not exist on type 'ProspectQuestionServerType'.
      if (question.isSingleSelectQuestion()) {
        return buildSingleSelectQuestionRequest(question, value);
        // @ts-expect-error - TS2339 - Property 'isMultiSelectQuestion' does not exist on type 'ProspectQuestionServerType'.
      } else if (question.isMultiSelectQuestion()) {
        return buildMultiSelectQuestionRequest(question, value);
        // @ts-expect-error - TS2339 - Property 'isPhoneNumberQuestion' does not exist on type 'ProspectQuestionServerType'.
      } else if (question.isPhoneNumberQuestion()) {
        // @ts-expect-error - TS2339 - Property 'internationalFormattedPhoneNumber' does not exist on type 'string | number | number[] | PhoneNumber'.
        if (!value || !value.internationalFormattedPhoneNumber) {
          return null;
        }

        return buildQuestionRequest(question, [
          {
            // @ts-expect-error - TS2339 - Property 'internationalFormattedPhoneNumber' does not exist on type 'string | number | number[] | PhoneNumber'.
            value: value.internationalFormattedPhoneNumber,
          },
        ]);
      }

      return buildQuestionRequest(question, [{ value }]);
    });

    // strip any values that are null
    questionRequests =
      questionRequests.filter((request) => request !== null) || [];

    let request = {
      uuid: this.state.uuid,
      event_id,
      resume: file ? file[0] : null, // unwrap from FileList to File
      prospect: {
        uuid: this.state.uuid,
        event_id,
        questions: questionRequests,
      },
    };

    if (!request.resume) {
      // @ts-expect-error - TS2790 - The operand of a 'delete' operator must be optional.
      delete request.resume;
    }
    return request;
  };

  validate = (): boolean => {
    const { event_configuration } = this.props.event;
    const { answers } = this.state;
    const newAnswers = { ...answers } as const;

    let isFormValid = true;

    event_configuration.questions.forEach((question) => {
      const answer = newAnswers[question.id];
      const validState = new ProspectQuestion(question).isValid(answer.value);

      if (validState.valid) {
        answer.error = '';
      } else {
        isFormValid = false;
        // @ts-expect-error - TS2322 - Type 'string | null | undefined' is not assignable to type 'string'.
        answer.error = validState.error;
      }
    });

    this.setState({ answers: newAnswers }, () => {
      if (isFormValid) {
        return;
      }

      // if there's an error, wait 100ms for everything to update and then scroll to
      // the first error
      setTimeout(() => {
        scrollToFirstError(
          `.${FIELD_CONTAINER_CLASS_NAME}.${ERROR_CLASS_NAME}`,
          ERROR_THRESHOLD
        );
      }, 100);
    });

    return isFormValid;
  };

  onSubmit = () => {
    const { submissionUrl } = this.props;
    const { submitting, uuid } = this.state;

    // start validation
    // don't proceed further if the form input is not valid or already in the process of
    // submitting
    if (!this.validate() || submitting) {
      return;
    }

    this.setState({ submitting: true });

    const request = this.prepareSubmissionRequest();

    standardRequest
      .post(submissionUrl, request, {
        transformRequest: [this.transformToMultipartFormData],
        headers: {
          'X-GH-Id': uuid,
        },
      })
      .then(() => {
        this.setState({
          submitting: false,
          submitted: true,
        });
      })
      
      .catch((res) => {
        this.setState(
          {
            submitting: false,
            error: getErrorText(delve(res, 'response.data') || {}),
          },
          () => {
            if (this.errorElement) {
              // scroll to the error element
              window.scrollTo(
                0,
                getOffset(this.errorElement).top - ERROR_THRESHOLD
              );
            }
          }
        );
      });
  };

  renderQuestions(
    questions: Array<ProspectQuestionServerType>
  ): Array<unknown> {
    const { answers, touchFriendly } = this.state;

    return questions.map((question, key) => {
      const onChange = (value: unknown) => {
        this.onAnswerChange(question.id, value);
      };

      return (
        <ProspectQuestionField
          key={key}
          question={new ProspectQuestion(question)}
          onChange={onChange}
          error={answers[question.id].error}
          value={answers[question.id].value}
          touchFriendly={touchFriendly}
        />
      );
    });
  }

  render() {
    const { event, maxFileSize } = this.props;
    const { logo_url } = event.event_configuration;
    const { file, error, submitting, submitted } = this.state;
    const questions = this.getQuestions(event);

    const showFileInfo = file && file.length > 0;
    const fileInfoText = showFileInfo ? file[0].name : '';

    return (
      <MainFormContainer>
        {logo_url && (
          <LogoContainer>
            <img src={logo_url} />
          </LogoContainer>
        )}

        {submitted && (
          <Confirmation>
            <Header center>{t('confirmation.header')}</Header>
          </Confirmation>
        )}

        {!submitted && (
          <div>
            <Header>{t('header')}</Header>

            {error && (
              <Error
                // @ts-expect-error - TS7006 - Parameter 'elem' implicitly has an 'any' type.
                ref={(elem) => {
                  this.errorElement = elem;
                }}
              >
                {error}
              </Error>
            )}

            <form action="" method="post">
              <Questions>{this.renderQuestions(questions)}</Questions>

              <ResumeUploadContainer>
                {showFileInfo ? (
                  <FileInfoWrapper>
                    <BlockLabel>{t('upload_resume.header')}</BlockLabel>
                    <FileIcon width={22} height={28} />
                    <FileInfoContainer
                      selectedFilesText={fileInfoText}
                      removeFilesText={t('upload_resume.remove')}
                      onRemoveLinkClick={this.onResumeRemoved}
                    />
                  </FileInfoWrapper>
                ) : (
                  <Button onClick={this.onSelectResumeClick}>
                    {t('upload_resume.select')}
                  </Button>
                )}

                <FileUploader
                  files={file}
                  // @ts-expect-error - TS7006 - Parameter 'input' implicitly has an 'any' type.
                  fileInputRef={(input) => {
                    this.fileInput = input;
                  }}
                  maxSize={maxFileSize}
                  onChange={this.onResumeSelected}
                  onFilesRejected={this.onResumeRejected}
                  acceptedMimeTypes={this.props.acceptedMimeTypes}
                  showPreview={false}
                  hidden
                />
              </ResumeUploadContainer>

              <ButtonBar>
                <Button
                  className="submit-button"
                  size="xlarge"
                  onClick={this.onSubmit}
                  disabled={submitting}
                  fancy
                >
                  {submitting ? t('submitting') : t('submit')}
                </Button>
              </ButtonBar>
            </form>
          </div>
        )}
      </MainFormContainer>
    );
  }
}

export const Confirmation = styled.div`
  box-sizing: border-box;
  max-width: ${MAX_FORM_WIDTH};
`;

const Questions = styled.div`
  margin-top: 40px;
`;

const ResumeUploadContainer = styled.div`
  margin-top: ${padding.normal};
`;

const ButtonBar = styled.div`
  border-top: 1px solid ${colors.lightGrey};

  // make sure this extends to the width of the parent container
  // so the border is correct
  margin: calc(-1 * ${MAIN_CONTAINER_PADDING});
  margin-top: 40px;

  padding: ${padding.normal} ${MAIN_CONTAINER_PADDING};

  text-align: right;

  @media (max-width: 500px) {
    // on mobile resolutions, just center align the submit button
    text-align: center;
  }
`;

const FileInfoWrapper = styled.div`
  svg {
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
  }

  .file-info-container {
    display: inline-block;
    margin: 0;
    vertical-align: middle;
  }
`;
