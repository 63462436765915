import Mounter from 'shared/utils/mounter';
import UploadResumePage from 'events/upload_resume/components/upload_resume_page';
import NewProspectForm from 'events/prospects/components/new_prospect_form';
import EventUnavailable from 'events/prospects/components/event_unavailable';
import documentReady from 'document-ready';

documentReady(() => {
  new Mounter({
    UploadResumePage,
    NewProspectForm,
    EventUnavailable,
  }).mountAll();
});
