import React from 'react';
import {
  Header,
  Description,
  LogoContainer,
  MainFormContainer,
  // @ts-expect-error - TS7016 - Could not find a declaration file for module './styles'. 'app/webpack/javascripts/events/prospects/components/styles.js' implicitly has an 'any' type.
} from './styles';

type Props = {
  logo_url: string;
  title: string;
  message: string;
};

export default class EventUnavailable extends React.Component<Props> {
  shouldComponentUpdate() {
    // this is just a static form
    return false;
  }

  render() {
    const { logo_url, title, message } = this.props;

    return (
      <MainFormContainer>
        {logo_url && (
          <LogoContainer>
            <img src={logo_url} />
          </LogoContainer>
        )}

        <Header>{title}</Header>
        <Description>{message}</Description>
      </MainFormContainer>
    );
  }
}
